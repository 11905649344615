import axios from "axios";

export default {
  async get(fromDate, toDate) {
    return await axios
      .get(
        `report/internal-transfer-report?fromDate=${fromDate}&toDate=${toDate}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getInternalTransferDetails(id) {
    return await axios
      .get(`report/Internal-Transfer-Report/details/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
