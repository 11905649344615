<template>
  <div>
    <v-container fluid class="px-6 py-3">
      <v-row>
        <!-- #region Card section 1 -->
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="px-0 py-0">
              <v-row class="pa-4 ms-3">
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Date</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ formatDate(this.internalTransfer.date) }}</label>
                  </v-row>
                </v-col>

                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">From warehouse</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.internalTransfer.from_warehouse }}</label>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">To warehouse</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.internalTransfer.to_warehouse }}</label>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">PO number</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.internalTransfer.po_number }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Status</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      this.internalTransfer.po_number ? "Synced" : "Not synced"
                    }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <v-btn
                    @click="goBack()"
                    elevation="0"
                    :ripple="false"
                    height="40"
                    dark
                    color="blue"
                    class="text-capitalize btn-ls btn-primary bg-success py-3 px-6 ml-2"
                    >Back</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <v-data-table
              :headers="headers"
              :items="internalTransferDetails"
              item-key="id"
              mobile-breakpoint="0"
              fixed-header
              class="table"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
            >
              <template v-slot:[`item.gpDate`]="{ item }">
                <span>{{ formatDate(item.gpDate) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  name: "InternalTransferDetail",
  data() {
    return {
      internalTransfer: null,
      internalTransferDetails: [],
      overlay: false,
      currentPage: {},
      headers: [
        {
          text: "mark",
          value: "mark",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: true,
          filterable: true,
        },
        {
          text: "grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: true,
          filterable: true,
        },
        {
          text: "invoice",
          value: "invoice",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: true,
          filterable: true,
        },
        {
          text: "item name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: true,
          filterable: true,
        },
        {
          text: "Bags",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: true,
          filterable: true,
        },

        {
          text: "Wt",
          value: "net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "net",
          value: "total_net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        // {
        //   text: "purchased rate",
        //   value: "purchased_rate",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   sortable: false,
        //   filterable: true,
        // },

        // {
        //   text: "initial purchase type",
        //   value: "initial_purchase_type",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   sortable: false,
        //   filterable: true,
        // },
        // {
        //   text: "tea value",
        //   value: "tea_value",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   sortable: false,
        //   filterable: true,
        // },
      ],
    };
  },
  watch: {
    internalTransfer(val) {
      if (!val) {
        this.$router.go(-1);
      }
    },
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    goBack() {
      this.$router.go(-1);
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 2000,
        icon: "success",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 5000,
        icon: "error",
      });
      this.overlay = false;
    },
  },
  async mounted() {
    this.internalTransfer = this.$route.params.internalTransfer;
    this.currentPage = this.$route.params.currentPage;

    this.internalTransferDetails = await api.getInternalTransferDetails(this.internalTransfer.id);
  },
};
</script>

<style></style>
